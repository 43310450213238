<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		
		<lr-header @toSection="toSection"></lr-header>
		
		<div class="download_top">
			<div class="download_top_title">下载中心</div>
			<div class="download_top_title_remark">产品电子说明书在线预览与下载</div>
		</div>
		
		<div class="download">
			<div class="download_content">
				<div class="download_row">
					<div class="download_left_row1">
						<img src="../../assets/img/lr/img_download.png" alt=""/>
					</div>
					<div class="download_right_row1">
						<div class="download_right_row1_name">
							<div class="download_right_row1_name_title">产品名称：</div>
							<div class="download_right_row1_name_memo">温度持续监测传感器（适用APP：小竹-智慧体温管家）</div>
						</div>
						<div class="download_right_row1_mode">
							<div class="download_right_row1_mode_title">产品型号：</div>
							<div class="download_right_row1_mode_memo">ZT101</div>
						</div>
						<div class="download_right_row1_book">
							<img src="../../assets/img/lr/icon-download.png" alt=""/>
							<div class="download_right_row1_book_text">
								<span class="download_right_row1_book_name">
									下载说明书
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="download_row">
					<div class="download_left_row2">
						<div class="download_left_row2_name">
							<div class="download_left_row2_title">产品名称：</div>
							<div class="download_left_row2_memo">温度持续监测传感器（适用APP：小竹-智慧体温管家）</div>
						</div>
						<div class="download_left_row2_mode">
							<div class="download_left_row2_mode_title">产品型号：</div>
							<div class="download_left_row2_mode_memo">ZT101</div>
						</div>
						<div class="download_left_row2_book">
							<img src="../../assets/img/lr/icon-download.png" alt=""/>
							<div class="download_left_row2_book_text">
								<span class="download_left_row2_book_name">
									下载说明书
								</span>
							</div>
						</div>
					</div>
					
					<div class="download_right_row2">
						<img src="../../assets/img/lr/img_download.png" alt=""/>
					</div>
				</div>
				
			</div>
		</div>
		<!-- footer -->		
		<lr-footer id="footer" :footerLogo="logo" :companyName="companyName" :companyNameEn="companyNameEn"
			:companyTel="companyTel" :company-email="companyEmail" :companyPhone="companyPhone"></lr-footer>
			
		<!-- end -->
	</div>
</template>

<script>
import lrFooter from "../../components/lrfooter.vue";
import lrHeader from "../../components/lrheader.vue";
import {data} from "../../assets/js/data.js";

export default {
	name: "download",
	components: {
		lrHeader,
		lrFooter
	},
	data() {
		return {
			itemList:[{
				bgPath: require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			}],
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: data.companyPhone,
			companyEmail: "0451-84520036"
		};
	},
	methods: {
		jumpTo(url){
			this.$router.push(url);
		},
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});
			
		}
	},
};
</script>


<style scoped>
.download_top {
	width: 100%;
	height: 18.75rem;
	background: url(../../assets/img/lr/bg_download_top.png) 100% no-repeat;
	background-size: 100% 100%;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.download_top_title {
	height: 3.9375rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 3rem;
	color: #3D3D3D;
	line-height: 3.9375rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
.download_top_title_remark {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}

.download {
	padding-left: 17rem;
	padding-right: 15rem;
	/* width: 100%; */
	background-color: rgb(248, 248, 248);
	height: 58.0625rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.download_content {
	width: 90rem;
	height: 49.0625rem;
	display: flex;
	flex-direction: column;
}
.download_row {
	display: flex;
	flex-direction: row;
}
.download_left_row1 {
	width: 38.375rem;
	height: 23.75rem;
	background: #E6F1F9;
	border-radius: 0px 0px 0px 0px;
}
.download_left_row1 img{
	/* width: 37.0625rem;
	height: 21.5625rem; */
	border-radius: 0px 0px 0px 0px;
}
.download_right_row1{
	width: 41.9375rem;
	height: 23.6875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	padding-left: 5.0625rem;
}
.download_right_row1_name {
	width: 41.9375rem;
	/* height: 23.6875rem; */
	height: 6.875rem;
}
.download_right_row1_name_title {
	/* width: 120px; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	
	margin-top: 2.125rem;
}
.download_right_row1_name_memo {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}
.download_right_row1_mode {
	width: 41.9375rem;
	/* height: 29.125rem; */
}
.download_right_row1_mode_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row1_mode_memo {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}
.download_right_row1_book {
	/* width: 41.9375rem;
	height: 14.625rem; */
	
	width: 15.875rem;
	height: 5.0625rem;
	background: #5982D0;
	border-radius: 10px 10px 10px 10px;
	/* margin-left: 5.0625rem; */
	display: flex;
	align-items: center;
	margin-top: 0.6875rem;
}
	
.download_right_row1_book img {
	width: 3rem;
	height: 3rem;
	margin-left: 1.8125rem;
	
}
.download_right_row1_book_text {
	
}
.download_right_row1_book_name {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.download_left_row2 {
	margin-top: 3.75rem;
	
	width: 38.375rem;
	height: 23.75rem;
	display: flex;
	flex-direction: column;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	padding-left: 5.0625rem;
}
.download_left_row2_name {
	width: 41.9375rem;
	height: 9.875rem;
}
.download_left_row2_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 2.125rem;
}
.download_left_row2_memo {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}

.download_left_row2_mode_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_left_row2_mode_memo {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}
	
.download_left_row2_book {
	width: 15.875rem;
	height: 5.0625rem;
	background: #5982D0;
	border-radius: 10px 10px 10px 10px;
	display: flex;
	align-items: center;
	margin-top: 0.6875rem;
}
.download_left_row2_book img {
	width: 3rem;
	height: 3rem;
	margin-left: 1.8125rem;
}
.download_left_row2_book_text {
	
}
.download_left_row2_book_name {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.download_right_row2 {
	margin-top: 3.75rem;
	width: 41.9375rem;
	height: 23.6875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	/* padding-left: 5.0625rem; */
}
.download_right_row2 img {
	width: 41.9375rem;
	/* height: 23.6875rem; */
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	height: 23.6875rem; 
}
</style>