<template>
	<!-- star -->

	<div class="homepage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_top.png" alt="" />
			</div>
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_logo.png" alt="" />
					</div>
					<div class="navWrap_center_company_name">
						<span class="navWrap_center_company_name_text">哈尔滨了然科技开发有限公司</span>
						<span class="navWrap_center_company_name_text2">Harbin Liaoran Technology Development Co.</span>
					</div>

					<ul class="navWrap_center_ul">
						<li @click="jumpTo('/')">首页</li>
						<li @click="toSection('product')">产品中心</li>
						<li @click="toSection('about')">公司介绍</li>
						<li @click="toSection('news')">新闻资讯</li>
						<li @click="toSection('download')">下载中心</li>
						<li @click="toSection('footer')">联系我们</li>
					</ul>


					<!-- sys  -->
					<span class="header_sys_name">竹松兰</span>
					<div class="header_app_name">
						<span class="header_app_name_text">智慧体温管家</span>
						<div class="header_app_name_text2">
							新一代智能体温计，实时监测，了解体温升降趋势； <br/> 高烧报警，无需反复测量；即贴即测，安全舒适
						</div>
					</div>

					<div class="header_more">
						<span class="header_more_text">了解更多 ></span>
					</div>

				</div>


			</div>

			<div class="header_carousel">
				<van-swipe :autoplay="3000">
					<van-swipe-item v-for="(image, index) in deviceImages" :key="index">
						<img :src="image" alt="" />
					</van-swipe-item>
				</van-swipe>
			</div>
		</div>
		<!-- 最新资讯 -->
		<div class="product" id="product">
			<div class="prod_title">
				<span class="product_title_content">产品中心</span>
				<span class="product_title_more">更多></span>
			</div>
			<div class="product_group">
				<div class="product_group_left" @click="jumpTo('productDet')">
					<div class="product_group_left_content">
						<div class="product_content_title">
							<span class="product_content_title_txt">智能体温计</span>
							<br/>
							<span class="product_content_title_txt2">APP实时监测 | 高低温随时报警</span>
						</div>
					</div>
				</div>
				<div class="product_group_right">
					<div class="product_group_item" v-for="(item,index) in itemList" :key="index"
						:style="{'background': 'url(' + item.bgPath + ') 100% no-repeat', backgroundSize:'100% 100%'}">
						<div class="product_group_item_box">
							<div class="product_group_item_text_group">
								<span class="product_group_item_text">{{item.title}}</span>
								<br/>
								<span class="product_group_item_text2">{{item.content}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- 关于企业 -->
		<div class="AboutEnterprises">
			<div class="AboutEnterprises_cont" id="about">
				<!-- top -->
				<div class="about_content_text">

				</div>
				<div class="AboutEnterprises_cont_top">
					<!-- top_left -->
					<div class="AboutEnterprises_cont_top_left">
						<h1>关于企业</h1>
						<p>
						哈尔滨了然科技开发有限公司成立于2016年，是一家专注于专业医疗级可穿戴医疗器械研发的创新型企业，申请并注册了多项专利及新技术成果。研发团队是由中国科学院深圳先进技术研究院生物医学与健康工程研究所在医疗领域具有丰富经验和专业知识的专家以及哈尔滨工业大学深圳研究院的专业算法专家组成，研发团队达60余人。
						<br/>
						<br/>
						研发团队在智慧医疗体系建设，体征信息采集，医疗信息数字化，医疗大数据分析处理等方面积累了丰富临床经验。致力于对家用及临床体温、呼吸产品的研发，曾参与国家重点支持科研项目“海云工程”。
						<br/>
						<br/>
						企业秉承着“以患者为中心，以创新为动力”的企业理念，为患者和医务人员提供更加便利、安全、有效的产品，让健康了然于心。

						</p>
						<router-link v-if="false">
							<div class="AboutEnterprises_cont_top_left_btn">
								<span>了解更多 ></span>
							</div>
						</router-link>
					</div>
					<!-- top_right -->
					<div class="AboutEnterprises_cont_top_right">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_about.png" alt="" />
						<div></div>
					</div>
				</div>
				<!-- bottom -->
				<div class="AboutEnterprises_cont_bottom" v-if="false">
					<div class="about_static">
						<span class="about_static_txt">{{aboutData.statics1}}</span>
						<span class="about_static_txt_2">年+</span>
						<span class="about_static_txt2">{{aboutData.statics2}}</span>
						<span class="about_static_txt2_2">年+</span>
						<span class="about_static_txt3">{{aboutData.statics3}}</span>
						<span class="about_static_txt3_2">°</span>
						<span class="about_static_txt4">{{aboutData.statics4}}</span>
						<span class="about_static_txt4_2">+</span>
					</div>
					<div class="about_statictype" style="display: flex;">
						<span class="about_statictype_txt">{{aboutData.type1}}</span>
						<span class="about_statictype_txt2">{{aboutData.type2}}</span>
						<span class="about_statictype_txt3">{{aboutData.type3}}</span>
						<span class="about_statictype_txt4">{{aboutData.type4}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="download" id="download">
			<div class="download_title">
				<span class="download_title_content">下载中心</span>
			</div>
			<div class="download_content">
				<div class="download_row">
					<div class="download_left_row1">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_download_1.png" alt=""/>
					</div>
					<div class="download_right_row1">
						<div class="download_right_row1_name">
							<div class="download_right_row1_name_title">产品名称：</div>
							<div class="download_right_row1_name_memo">温度持续监测传感器（适用APP：小竹-智慧体温管家）</div>
						</div>
						<div class="download_right_row1_mode">
							<div class="download_right_row1_mode_title">产品型号：</div>
							<div class="download_right_row1_mode_memo">ZT102</div>
						</div>
						<a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt102.pdf" target="_blank" download="ZT102产品说明书.pdf">
						<div class="download_right_row1_book" >
							<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/icon-download.png" alt=""/>
							<div class="download_right_row1_book_text">
								<span class="download_right_row1_book_name">
									下载操作指南
								</span>
							</div>
						</div>
						</a>
					</div>
				</div>
				<div class="download_row">
					<div class="download_left_row2">
						<div class="download_left_row2_name">
							<div class="download_left_row2_title">产品名称：</div>
							<div class="download_left_row2_memo">温度持续监测传感器（适用APP：小竹-智慧体温管家）</div>
						</div>
						<div class="download_left_row2_mode">
							<div class="download_left_row2_mode_title">产品型号：</div>
							<div class="download_left_row2_mode_memo">ZT101</div>
						</div>
						<a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt101.pdf" target="_blank" download="ZT101产品说明书.pdf">
						<div class="download_left_row2_book">
							<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/icon-download.png" alt=""/>
							<div class="download_left_row2_book_text">
								<span class="download_left_row2_book_name">
									下载操作指南
								</span>
							</div>
						</div>
						</a>
					</div>

					<div class="download_right_row2">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_download_2.png" alt=""/>
					</div>
				</div>

				<div class="download_row" style="margin-top: 3.75rem;">
					<div class="download_left_row3">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_download_app.png" alt=""/>
					</div>
					<div class="download_right_row3">
						<div class="download_right_row3_name">
							<div class="download_right_row3_name_title">APP下载：小竹-智慧体温管家</div>
						</div>
						<div class="download_right_row3_mode">
							<div class="download_right_row3_mode_title">适用产品型号：</div>
							<div class="download_right_row3_mode_memo">ZT101、ZT102通用</div>
						</div>
						<div class="download_right_row3_app">
							<div class="download_right_row3_app_left">
								<div class="download_right_row3_app_left_qr">
									<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/qr_android.png" />
								</div>
								<span class="download_right_row3_app_right_text">安卓手机扫码下载</span>
							</div>
							<div class="download_right_row3_app_line"></div>
							<div class="download_right_row3_app_right">
								<div class="download_right_row3_app_right_qr">
									<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/qr_ios.png" />
								</div>
								<span class="download_right_row3_app_right_text">苹果手机扫码下载</span>
							</div>
						</div>

				</div>
				</div>



			</div>
		</div>

		<!-- 最新资讯 -->
		<div class="news" id="news">
			<div class="news_cont">
				<!-- left -->
				<div class="news_cont_left">
					<h1>新闻动态</h1>
					<div class="news_bg">
						<div class="news_cont_left_img">
							<img :src="newsData.newsImg" alt="" />
						</div>
						<div class="news_cont_left_text">
							<h1>{{newsData.title}}</h1>
							<p class="news_cont_left_text2">{{newsData.date}}</p>
							<p class="news_cont_left_text3">
								{{newsData.content}}
							</p>
						</div>
					</div>
				</div>
				<!-- right -->
				<div class="news_cont_right">
					<div class="news_cont_right_ul">
						<ul>
							<li>更多 > </li>
						</ul>
					</div>
					<div class="news_cont_right_text">
						<div
							class="news_cont_right_text_item news_cont_right_text_hr"
							v-for="(item, index) in newsList"
							:key="index"
							@click="newsArticle(index)"
						>
							<h1>{{ item.title }}</h1>
							<p>{{ item.date }}</p>
							<p class="news_cont_right_text_item_text">
								{{ item.content }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- footer -->

		<lr-footer id="footer" :footerLogo="logo" :companyName="companyName" :companyNameEn="companyNameEn"
			:companyTel="companyTel" :company-email="companyEmail" :companyPhone="companyPhone"></lr-footer>

		<!-- end -->
	</div>
</template>

<script>
import lrFooter from "../components/lrfooter.vue"
import {isMobile} from "@/assets/js/common";
import {data} from "../assets/js/data.js";

export default {
	name: "index",
	components: {
		lrFooter
	},
	data() {
		return {
			deviceImages:[
				"https://website2024.oss-cn-beijing.aliyuncs.com/pc/device_1.png",
				"https://website2024.oss-cn-beijing.aliyuncs.com/pc/device_2.png"
			],
			itemList:[{
				bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_zt101.png',
				title: "智能体温计-医用版-ZT101",
				content: " "
			},{
				bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_2.png',
				title: "血压袖带",
				content: " "
			},{
				bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_3.png',
				title: "一次性使用口腔冲洗器-A型",
				content: " "
			},{
				bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_4.png',
				title: "一次性使用口腔冲洗器-B型",
				content: " "
			}],
			aboutData:{
				titleEn:"BRAND",
				title: "关于企业",
				content: " 哈尔滨了然科技开发有限公司成立于2016年，是一家专注于专业医疗级可穿戴医疗器械研发的创新型企业，申请并注册了多项专利及新技术成果。研发团队是由中国科学院深圳先进技术研究院生物医学与健康工程研究所在医疗领域具有丰富经验和专业知识的专家以及哈尔滨工业大学深圳研究院的专业算法专家组成，研发团队达60余人。<br/>研发团队在智慧医疗体系建设，体征信息采集，医疗信息数字化，医疗大数据分析处理等方面积累了丰富临床经验。致力于对家用及临床体温、呼吸产品的研发，曾参与国家重点支持科研项目“海云工程”。<br/>企业秉承着“以患者为中心，以创新为动力”的企业理念，为患者和医务人员提供更加便利、安全、有效的产品，让健康了然于心。",
				statics1: "5",
				statics2: "10",
				statics3: "360",
				statics4: "80",
				type1: "专注医疗器械开发",
				type2: "人均研发经验",
				type3: "交付&售后保障",
				type4: "医疗行业服务案例"
			},
			newsData: {
				newsImg: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_news.png',
				title: "新闻动态标题",
				date : "2024-03-24",
				content : " 公司现有员工近二十人，公司下设销售部、质量管理部、技术售后服务部等部门。此外，公司设立的售后服务部…… "
			},
			newsList: [{
				title: "新闻动态标题",
				date : "2024-03-24",
				content : " 公司现有员工近二十人，公司下设销售部、质量管理部、技术售后服务部等部门。此外，公司设立的售后服务部…… "
			},{
				title: "新闻动态标题",
				date : "2024-03-24",
				content : " 公司现有员工近二十人，公司下设销售部、质量管理部、技术售后服务部等部门。此外，公司设立的售后服务部…… "
			},{
				title: "新闻动态标题",
				date : "2024-03-24",
				content : " 公司现有员工近二十人，公司下设销售部、质量管理部、技术售后服务部等部门。此外，公司设立的售后服务部…… "
			},]
			,
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: data.companyPhone,
			companyEmail: "0451-84520036"
		};
	},
	computed: {
		title() {
			return this.$store.state.title;
		},
	},
	created() {
		// 判断是否是移动端浏览器，自动跳转到移动端页面
		if(isMobile()){
			this.$router.push("mIndex")
			return;
		}
		let anchor = this.$route.query.mount;
		let that = this;
		setTimeout(function(){
			that.toSection(anchor);
		},1000);
	},
	methods: {
		newsArticle(index) {
			console.log(index);
			// this.$router.push("/newsxiangqing" + index);
		},
		jumpTo(url){
			this.$router.push(url);
		},
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				if(!el){
					return;
				}
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});

		},
		downloadBook(){
			// 102
		},
		downloadBook2(){
			// 101
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}

.banner_nav {
	width: 100vw;
	height: 800px;
	position: relative;
}

.banner_nav_bg img {
	/* 文本居顶部对齐 */
	vertical-align: top;
}

/* 导航部分 */

.navWrap {
	width: 96vw;
	position: absolute;
	top: 0;
	left: 0;
	/*  */
	padding-top: 2.5rem;
	padding-left: 2%;
	/*padding-left: 17rem;
	padding-right: 15rem;*/
}

.navWrap_center {
	/*width: 1440px;*/
	height: 101px;
	margin: 0 auto;
	background: #fff;
	border-radius: 51px;
	/*  */
	display: flex;
	justify-content: flex-start;
}

.navWrap_center_img {
	/* width: 181px;
	height: 54px;
	margin: 23px 0 0 120px; */
	width: 50px;
    height: 69px;
    margin: 17px 0 0 6.625rem;
}

.navWrap_center_img img {
	width: 50px;
}

.navWrap_center_company_name{
	/*width: 29%;*/
	height: 3.625rem;
	margin: 1.625rem 0 0 1.0625rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.navWrap_center_company_name_text {
    height: 1.6875rem;
    overflow-wrap: break-word;
    color: rgba(61, 61, 61, 1);
    font-size: 1.5rem;
    font-family: DingTalk JinBuTi-Regular;
    text-align: left;
    white-space: nowrap;
    line-height: 1.8125rem;
}
.navWrap_center_company_name_text2 {
    height: 1.6875rem;
    overflow-wrap: break-word;
    color: rgba(61, 61, 61, 1);
    font-size: 0.875rem;
    font-family: DingTalk JinBuTi-Regular;
    text-align: left;
    white-space: nowrap;
    line-height: 1.0625rem;
    margin: 4px 0 0 1px;
}

.navWrap_center_ul {
	/* border:1px solid red; */
	/*margin-right: 7.5625rem;*/
}

.navWrap_center_ul li {
	display: inline-block;
	height: 98px;
	line-height: 98px;
	/* border-bottom: 4px solid blue; */
	margin-left: 50px;
	padding: 0 5px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	/* line-height: 22px; */
}
.navWrap_center_ul li:hover {
	cursor: pointer;
	color: rgba(35, 51, 127, 1);
	border-bottom: 2px solid rgba(35, 51, 127, 1);
}
/* sys */
.header_sys_name {
	height: 2.625rem;
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 2rem;
	font-family: Microsoft YaHei-Regular;
	text-align: left;
	white-space: nowrap;
	line-height: 2.625rem;
	margin: 0 0 0 8.625rem;
	position: absolute;
	top: 18.6875rem;
}

.header_app_name {
	height: 8.125rem;
	margin: 0 0 0 8.625rem;
	justify-content: space-between;
	position: absolute;
	top: 21.9375rem;
}

.header_app_name_text {
	height: 3.9375rem;
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 3rem;
	font-family: Microsoft YaHei-Regular;
	text-align: left;
	white-space: nowrap;
	line-height: 3.9375rem;
}

.header_app_name_text2 {
	height: 3rem;
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 1.125rem;
	font-family: Microsoft YaHei-Regular;
	text-align: left;
	line-height: 1.5rem;
	margin-top: 1.1875rem;
}

.header_more{
	background-color: #5982D0;
	border-radius: 13.375rem;
	height: 3.125rem;
	line-height: 3.125rem;
	width: 10.0625rem;
	margin: 0 0 0 8.625rem;
	top: 33.9375rem;
	position: absolute;
	text-align: center;
}

.header_more_text {
	height: 1.125rem;
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 0.875rem;
	font-family: Microsoft YaHei-Regular;
	text-align: left;
	white-space: nowrap;
	line-height: 1.125rem;
}

/* title */

.banner_title_wrap {
	width: 100%;
	position: absolute;
	bottom: 290px;
	/* border: 1px solid yellow; */
}

.banner_title_center {
	/* border: 1px solid white; */
	text-align: center;
	width: 1200px;
	margin: 0 auto;
	font-size: 90px;
	font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
	font-weight: bold;
	color: #ffffff;
	line-height: 124px;
	letter-spacing: 1px;
}

.banner_title_center div {
	padding-top: 14px;
	font-size: 20px;
	font-family: Helvetica;
	line-height: 24px;
	letter-spacing: 4px;
}

.banner_title_center div span {
	margin: 0 18px;
}
/* news */
.news {
	width: 100vw;
	height: 50.0625rem;
	background: #f8f8f8;

	background: url(https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_news.png) 100% no-repeat;
	background-size: 100% 100%;
}
.news_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;


}
.news_cont_left {
	margin-top: 89px;
}
.news_cont_left_img img {
	width: 588px;
	height: 353px;
	margin-top: 41px;
}
.news_cont_left_text {
	padding-top: 27px;
	padding-left: 40px;
	width: 548px;
	height: 150px;
	background-color: #ffffff;
}
.news_cont_left_text h1 {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #1E46BF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.news_cont_left_text p {
	margin-top: 0.5625rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #6A6A6A;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.news_cont_left_text3 {
	color: #3d3d3d;
}
.news_cont_right {
	width: 588px;
	margin-top: 103px;
}
.news_cont_right_ul ul {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul a {
	font-size: 16px;
	font-weight: 400;
	color: #3d3d3d;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul li:hover {
	color: #23337f;
}
.news_cont_right_ul ul span {
	margin: 0 23px 0 24px;
}
.news_cont_right_text {
	width: 588px;
	height: 535px;
	background: #ffffff;
	margin-top: 68px;
}
.news_cont_right_text_item {
	padding: 36px;
}
.news_cont_right_text_item h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #1E46BF;
	line-height: 25px;
}
.news_cont_right_text_item p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #6A6A6A;
	line-height: 20px;
}
.news_cont_right_text_item_text {
	color: #3d3d3d;
}
.news_cont_right_text_hr {
	/* float: right; */
	width: 508px;

	border-bottom: 1px solid #d7d7d7;
}
.news_cont_right_text_hr:nth-of-type(3) {
	/* float: right; */
	width: 508px;

	border-bottom: none;
}
.news_cont_right_text_item2 {
	padding: 24px 40px;
}
.news_cont_right_text_item2 h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item2 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
/* 集团产业 */
.GroupIndustry {
	width: 100vw;
	height: 800px;
	background: #ffffff;
}
.GroupIndustry_cont {
	width: 1200px;
	margin: 0 auto;

	display: flex;
	justify-content: space-between;
}
.GroupIndustry_cont_left_title {
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 50px;
	margin: 84px 0 40px 0;
}
.GroupIndustry_cont_left_img_f {
	width: 793px;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.GroupIndustry_cont_left_img {
	margin-bottom: 24px;

	position: relative;
}
.GroupIndustry_cont_left_img img:first-child {
	width: 384px;
	height: 261px;
	background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}
.GroupIndustry_cont_left_img2 {
	position: absolute;
	left: 0;
	bottom: 0;
}

.GroupIndustry_cont_left_text {
	position: absolute;
	left: 32px;
	bottom: 18px;
}
.GroupIndustry_cont_left_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
	margin-top: 10px;
}
.GroupIndustry_cont_left_text span {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
.GroupIndustry_cont_right {
	position: relative;
	margin-top: 98px;
}
.GroupIndustry_cont_right_title {
	float: right;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 22px;
	margin-bottom: 54px;
}

.GroupIndustry_cont_right_img {
	width: 384px;
	height: 546px;
}
.GroupIndustry_cont_right_img2 {
	position: absolute;
	bottom: 24px;
}
.GroupIndustry_cont_right_text {
	position: absolute;
	left: 45px;
	bottom: 45px;
}
.GroupIndustry_cont_right_text p {
	font-size: 24px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 33px;
}
.GroupIndustry_cont_right_text span {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
}
/* 关于企业 */
.AboutEnterprises {
	width: 100vw;
	height: 50rem;
	background: url("https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_about.png") 100% no-repeat;
	background-size : 100% 100%;
}
.AboutEnterprises_cont {
	width: 1200px;
	margin: 0 auto;
	height: 100%;
}
.about_content_text {
	display: block;
	/* margin-top: 22.0625rem;
	margin-left: 18.5rem;*/
	position: relative;
	top: 5.25rem;
	left: -3.125rem;

	height: 8.1875rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 6.1875rem;
	color: rgba(90,134,219,0.26);
	line-height: 8.1875rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.AboutEnterprises_cont_top {
	display: flex;
	justify-content: space-between;
}
.AboutEnterprises_cont_top_left {
	margin-top: 0.9375rem;
	/* margin-left: 3.125rem; */
	width: 37.5rem;
}
.AboutEnterprises_cont_top_left > h1 {
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 50px;
}
.AboutEnterprises_cont_top_left p {
	margin-top: 36px;

	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 40px;
}
.AboutEnterprises_cont_top_left_btn {
	width: 9rem;
	height: 2.5rem;
	margin-top: 5.0625rem;
	display: flex;
	align-items: center;
	background-color: /* rgba(1, 118, 255, 1) */ #5982D0;
	border-radius: 13.375rem;
}
.AboutEnterprises_cont_top_left_btn img {
	width: 24px;
	height: 24px;

	margin-left: 8px;
}
.AboutEnterprises_cont_top_left_btn span {
	margin-left: 32px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 22px;
}
.AboutEnterprises_cont_top_right {
	position: relative;
	width: 487px;
	height: 517px;
	margin-top: 115px;
}
.AboutEnterprises_cont_top_right img {
	position: absolute;
	z-index: 1;
}
.AboutEnterprises_cont_top_right Div {
	width: 317px;
	height: 408px;
	background: #3491e5;
	position: absolute;
	top: 64px;
	left: -33px;
}
.AboutEnterprises_cont_bottom {
	/* display: flex;
	margin-top: 80px */;
}
.AboutEnterprises_cont_bottom_item {
	margin-right: 70px;
	position: relative;
	width: 247px;
	border-right: 1px solid #ffffff;
	display: flex;
}
.AboutEnterprises_cont_bottom_item3 {
	margin-right: 70px;
	position: relative;
	width: 323px;
	border-right: 1px solid #ffffff;
	display: flex;
}
.AboutEnterprises_cont_bottom_item3 p {
	width: 86px;
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #b30588;
	line-height: 28px;
}
.AboutEnterprises_cont_bottom_item3 h1 {
	font-size: 60px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 84px;
}
.AboutEnterprises_cont_bottom_item3 span {
	font-size: 17px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #692020;
	line-height: 24px;
}
.AboutEnterprises_cont_bottom_item4 {
	position: absolute;
	left: 161px;
	bottom: 15px;
}

.AboutEnterprises_cont_bottom_item h1 {
	font-size: 60px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 84px;
}
.AboutEnterprises_cont_bottom_item span {
	font-size: 17px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #692020;
	line-height: 24px;
}
.AboutEnterprises_cont_bottom_item p {
	width: 86px;
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #b30588;
	line-height: 28px;
}

.AboutEnterprises_cont_bottom_item2 {
	position: absolute;
	left: 85px;
	bottom: 15px;
}
/* 合作伙伴 */
.CooperativePartner {
	width: 100vw;
	height: 798px;
}
.CooperativePartner_cont {
	width: 1200px;
	margin: 0 auto;
}
.CooperativePartner_cont_title {
	text-align: center;
	margin-top: 80px;
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 50px;
}
.CooperativePartner_cont_center {
	display: flex;
	justify-content: space-between;
	width: 365px;
	height: 69px;

	margin: 0 auto;
	margin-top: 47px;
}
.CooperativePartner_cont_center_item {
	display: flex;
	align-items: center;
}
.CooperativePartner_cont_center_item:hover {
	border-bottom: 1px solid #3491e5;
}
.CooperativePartner_cont_center_item img {
	width: 40px;
	height: 40px;
}
.CooperativePartner_cont_center_item span {
	line-height: 69px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 33px;
	margin-left: 14px;
}
.CooperativePartner_cont_bottom {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 1200px;
	height: 392px;
	margin: 0 auto;
	margin-top: 80px;
}
.CooperativePartner_cont_bottom_logo img {
	width: 227px;
	height: 120px;
	background: #ffffff;
	box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
	border-radius: 9px;
	margin-bottom: 16px;
}
.dropbtn {
	background-color: #fff;
	color: rgb(12, 11, 11);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropdown {
	top: 22px;
	left: 394px;
	position: relative;
	display: inline-block;
}

.dropdown-content {
	text-align: center;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 95px;
	min-height: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
	color: rgb(6, 6, 6);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
}
</style>

<style scoped >
	.product {
		background: url(https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_product.png) 100% no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 55.625rem;
	}

.prod_title {
	width: 75rem;
	/* padding: 6rem 21.6875rem 0 21.875rem; */
	justify-content: space-between;
	display: flex;
	margin: 0 auto;

	padding-top: 6rem;
	padding-bottom: 2.8125rem;
}
.product_title_content {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_title_more {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.product_group{
	/* padding: 5.8125rem 21.875rem 0 21.875rem; */
	height: 35.3125rem;
	display: flex;
	width: 75rem;
	margin: 0 auto;
}
.product_group_left {
	width: 32%;
	height: 100%;
	background: url(https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_prodct_content.png) 100% no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
}
.product_group_left_content{
	background: linear-gradient( 180deg, rgba(52,103,203,0) 0%, #3656B6 83%);
	margin-top: 31.0625rem;
	/* width: 24.3125rem; */
	height: 4.25rem;
	border-radius: 0px 0px 0px 0px;
	/* position: absolute; */
}
.product_content_title {
	margin-top: 4.5625rem;
	margin-left: 1.1rem;
}
.product_content_title_txt {
	width: 100%;
	height: 2.0625rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 24px;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_content_title_txt2 {
	width: 100%;
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 0.9375rem;
	color: #FFFFFF;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_group_right {
	margin-left: 1.5625rem;
	width: 67%;
	/* flex: 2; */
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.product_group_item {
	width: 47.5%;
	height: 17.875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	/* margin-top: 5px; */
}

.product_group_item_box {
	height: 4.525rem;
	margin-top: 13.35rem;
	background-image: linear-gradient( 180deg, rgba(26, 51, 182, 0) 0, rgba(30, 70, 191, 1) 83.291394% );
}

.product_group_item_text_group {
	margin: 20% 0 0 5%;

}
.product_group_item_text{
	overflow-wrap: break-word;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.125rem;
	color: #FFFFFF;
	line-height: 3.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_group_item_text2 {
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 0.925rem;
	font-family: Microsoft YaHei-Regular;
	text-align: left;
	white-space: nowrap;
	line-height: 1.5rem;
	margin-top: 0.8125rem;
}

.about_static {
	width: 100%;
}
.about_static_txt {
	width: 2.75rem;
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.about_static_txt_2 {
	width: 3.625rem;
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_static_txt2{
	width: 4.5625rem;
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

	margin-left: 14.625rem;
}
.about_static_txt2_2{
	width: 3.625rem;
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_static_txt3 {
	margin-left: 12.75rem;

	width: 8.125rem;
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_static_txt3_2{
	width: 0.8125rem;
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_static_txt4{
	margin-left: 14.4375rem;
	width: 5.4375rem;
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_static_txt4_2{
	width: 1.375rem;
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_statictype {
	width: 100%;
	margin-top: 0.25rem;
}
.about_statictype_txt {
	/* margin-left: ; */
	width: 20rem;
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

}
.about_statictype_txt2 {
	/* margin-left: 9.4375rem; */
	width: 15rem;
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;


	position: absolute;
	left: 32.625rem;
}
.about_statictype_txt3 {
	/* margin-left: 12.4375rem; */
	width: 15rem;
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

	position: absolute;
	left: 54.0625rem;
}
.about_statictype_txt4 {
	/* margin-left: 12.4375rem; */
	width: 20rem;
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

	position: absolute;
	left: 77rem;
}

.footer {
	width: 100%;
	height: 23rem;
	background: url(https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_footer.png) 100% no-repeat;
	background-size: 100% 100%;
}
.footer_content {
	width: 75rem;
	margin: 0 auto;
	display: flex;
}
.footer_left {
	width: 75%;
}
.footer_company {
	padding-top: 4.1875rem;
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
}
.footer_company_name {
	display: flex;
	flex-direction: column;
	padding-top: 0.625rem;
	padding-left: 1.0625rem;
}
.footer_company_name_text {
	font-family: DingTalk JinBuTi, DingTalk JinBuTi;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 1.8125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.footer_company_name_text2 {
	font-family: DingTalk JinBuTi, DingTalk JinBuTi;
	font-weight: 400;
	font-size: 0.875rem;
	color: #FFFFFF;
	line-height: 1.0625rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.footer_concat {
	padding-top: 4.25rem;
	display: flex;
}

.footer_tel {
	display: flex;
	flex-direction: column;
	width: 20.375rem;
}
.span_text {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #FFFFFF;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.span_text2 {
	margin-top: 0.6875rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.875rem;
	color: #FFFFFF;
	line-height: 2.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.footer_phone {
	display: flex;
	flex-direction: column;
	width: 17.9375rem;
}

.footer_email {
	display: flex;
	flex-direction: column;
	width: 20.375rem;
}

.footer_right{
	display: flex;
	flex-direction: column;
	width: 25%;
	/* text-align: center; */
	align-items: flex-end;
	justify-content: flex-end;
}
.footer_right_qr {
	width: 8.0625rem;
	height: 8.0625rem;
	background: #FFFFFF;
	border-radius: 11px 11px 11px 11px;
}
.footer_right_text{
	margin-top: 1.3125rem;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #FFFFFF;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}


.header_carousel {
	position: absolute;
	top: 11.625rem;
	left: 42rem;

	width: 40rem;
	height: 30rem;

}
.van-swipe-item img {
	width: 40rem;
	height: 30rem;
}
</style>




<style scoped>

.download {
	/*padding-left: 17rem;
	padding-right: 15rem;*/
	width: 100%;
	/* background-color: rgb(248, 248, 248); */
	background-color: #FFFFFF;
	height: 105.3125rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.download_title {
	/*height: 12.4375rem;*/
	width: 1200px;
	margin: 0 auto;
	padding-top: 7.25rem;
	padding-bottom: 2.1875rem;
	display: flex;
	align-content: flex-start;
}
.download_title_content {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.download_content {
	width: 1200px;
	margin: 0 auto;
	/*height: 49.0625rem;*/
	display: flex;
	flex-direction: column;
}
.download_row {
	display: flex;
	flex-direction: row;
}
.download_left_row1 {
	width: 38.375rem;
	height: 23.75rem;
	background: #E6F1F9;
	border-radius: 0px 0px 0px 0px;
}
.download_left_row1 img{
	/* width: 37.0625rem;
	height: 21.5625rem; */
	border-radius: 0px 0px 0px 0px;
}
.download_right_row1{
	width: 41.9375rem;
	height: 23.6875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	padding-left: 5.0625rem;
}
.download_right_row1_name {
	width: 41.9375rem;
	/* height: 23.6875rem; */
	height: 6.875rem;
}
.download_right_row1_name_title {
	/* width: 120px; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

	margin-top: 2.125rem;
}
.download_right_row1_name_memo {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}
.download_right_row1_mode {
	width: 41.9375rem;
	/* height: 29.125rem; */
}
.download_right_row1_mode_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row1_mode_memo {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}
.download_right_row1_book {
	/* width: 41.9375rem;
	height: 14.625rem; */

	width: 15.875rem;
	height: 5.0625rem;
	background: #5982D0;
	border-radius: 10px 10px 10px 10px;
	/* margin-left: 5.0625rem; */
	display: flex;
	align-items: center;
	margin-top: 0.6875rem;
}

.download_right_row1_book img {
	width: 3rem;
	height: 3rem;
	margin-left: 1.8125rem;

}
.download_right_row1_book_text {

}
.download_right_row1_book_name {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.download_left_row2 {
	margin-top: 3.75rem;

	width: 38.375rem;
	height: 23.75rem;
	display: flex;
	flex-direction: column;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	/*padding-left: 5.0625rem;*/
}
.download_left_row2_name {
	width: 41.9375rem;
	height: 9.875rem;
}
.download_left_row2_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 2.125rem;
}
.download_left_row2_memo {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}

.download_left_row2_mode_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_left_row2_mode_memo {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.25rem;
}

.download_left_row2_book {
	width: 15.875rem;
	height: 5.0625rem;
	background: #5982D0;
	border-radius: 10px 10px 10px 10px;
	display: flex;
	align-items: center;
	margin-top: 0.6875rem;
}
.download_left_row2_book img {
	width: 3rem;
	height: 3rem;
	margin-left: 1.8125rem;
}
.download_left_row2_book_text {

}
.download_left_row2_book_name {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.download_right_row2 {
	margin-top: 3.75rem;
	width: 41.9375rem;
	height: 23.6875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	/* padding-left: 5.0625rem; */
}
.download_right_row2 img {
	width: 41.9375rem;
	/* height: 23.6875rem; */
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	height: 23.6875rem;
}


.download_left_row3 {
	width: 38.375rem;
	height: 23.75rem;
	background: #E6F1F9;
	border-radius: 0px 0px 0px 0px;
}
.download_left_row3 img{
	/* width: 37.0625rem;
	height: 21.5625rem; */
	border-radius: 0px 0px 0px 0px;
}

.download_right_row3 {
	width: 41.9375rem;
	height: 23.6875rem;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	padding-left: 5.0625rem;
}
.download_right_row3_name {
	/*height: 4.6875rem;*/
}
.download_right_row3_name_title {
	margin-top: 2.6875rem;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_mode {
	display: flex;
	flex-direction: column;
	margin-top: 1.875rem;

}
.download_right_row3_mode_title {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_mode_memo {
	margin-top: 1.25rem;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app {
	margin-top: 2.5625rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.download_right_row3_app_left {
	display: flex;
	flex-direction: column;
}
.download_right_row3_app_andriod {
	width: 15.875rem;
	height: 5.0625rem;
	background: #458470;
	border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;

	display: flex;
	flex-direction: row;
	align-items: center;
}
.download_right_row3_app_andriod img{
	width: 2.989375rem;
	height: 2.989375rem;
	margin-left: 1.875rem;
}
.download_right_row3_app_andriod_text {
	display: flex;
	flex-direction: column;
	margin-left: 0.875rem;
}
.download_right_row3_app_andriod_name {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app_andriod_name_en {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 0.875rem;
	color: #FFFFFF;
	line-height: 1.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app_ios {
	margin-top: 1.6875rem;

	width: 15.875rem;
	height: 5.0625rem;
	background: #535353;
	border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.download_right_row3_app_ios img{
	width: 2.42875rem;
	height: 2.99125rem;
	margin-left: 1.875rem;
}
.download_right_row3_app_ios_text {
	display: flex;
	flex-direction: column;
	margin-left: 0.875rem;
}
.download_right_row3_app_ios_name {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app_ios_name_en {
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 0.875rem;
	color: #FFFFFF;
	line-height: 1.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app_left_qr img{
	width: 9rem;
	height: 9rem;
	border-radius: 0px 0px 0px 0px;
}
.download_right_row3_app_right_text {
	margin-top: 0.5rem;
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
.download_right_row3_app_line {
	border-right: 1px solid #7B7B7B;
	width: 1px;
	height: 11.8125rem;
}
.download_right_row3_app_right {
	width: 11.8125rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.download_right_row3_app_right_qr img{
	width: 9rem;
	height: 9rem;
}
.download_right_row3_app_right_text {
	margin-top: 1.125rem;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
</style>

<style scoped>
.footer_content {
	width: 1200px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin: 0 auto !important;
}
.footer_left {
	width: 75%;
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
}
.footer_company {
	padding-top: 0px !important;

}
</style>
