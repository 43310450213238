<template>
	<!-- star -->
	<div class="mp-page">
		<div class="mp-top">
			<!-- 菜单位 -->
			<div class="mp-top-menu">
				<div class="mp-top-menu-left">
					<div class="mp-top-menu-logo">
						<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_logo.png" alt="" />
					</div>
					<div class="mp-top-menu-company">
						<div class="mp-top-menu-company-name">哈尔滨了然科技开发有限公司</div>
						<div class="mp-top-menu-company-name-en">Harbin Liaoran Technology Development Co.</div>
					</div>
				</div>
				<div class="mp-top-menu-right" @click="showMenu = !showMenu">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_menu.png" alt="" />
				</div>
			</div>
			<div class="mp-top-title">
				<div class="mp-top-title-left">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/img_app.png" alt="" />
				</div>
				<div class="mp-top-title-right">
					<div class="mp-top-title-text">小竹智慧体温管家</div>
					<div class="mp-top-title-soft-name">新一代体温计，APP实时监测</div>
				</div>
			</div>
		</div>

		<!-- 内容 -->
		<div class="mp-content">
			<div class="mp-product_introduce">
				<div class="mp-product_introduce_title">产品介绍：</div>
				<div class="mp-product_introduce_content">该设备为温度持续监测传感器，有2个型号，ZT101和ZT102，可用于患者耳温和腋温的持续监测与APP实时提醒。</div>
				<div class="mp-product_introduce_line"></div>
				<div class="mp-product_introduce_zt102">ZT102：适合检测腋温，可重复使用。</div>
				<div class="mp-product_introduce_img_zt102">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/img_product_show.png" alt=""/>
				</div>
				<div class="mp-product_introduce_line"></div>
				<div class="mp-product_introduce_zt101">ZT101：适合检测耳温，建议一次性使用。</div>
				<div class="mp-product_introduce_img_zt101">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/img_product_show_zt101.png" alt=""/>
				</div>
			</div>
			<div class="mp-product_advantage">
				<div class="mp-product_advantage_title">功能优势：</div>
				<div class="mp-product_advantage_content">无线传输 | 实时监测 | 高准确度 | 高低温提醒 <br/>温度补偿 | 历史测温记录 | 温度变化曲线</div>
				<div class="mp-product_introduce_line"></div>
			</div>
			<div class="mp-product_business">
				<div class="mp-product_business_title">适用场景：</div>
				<div class="mp-product_business_content">医院科室、居家旅行均适用</div>
				<div class="mp-product_introduce_line"></div>
			</div>
			<div class="mp-product_download">
				<div class="mp-product_download_app" id="download">
					<div class="mp-product_download_app_title">APP下载：</div>
					<div class="mp-product_download_app_link">
						<a :href="downAndroidloadUrl" target="_blank">
							<div class="mp-product_download_app_link_andriod">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/android_icon.png" alt=""/>
								<div class="mp-product_download_app_link_andriod_text">
									<span class="mp-product_download_app_link_andriod_name">
										Android
									</span>
									<span class="mp-product_download_app_link_andriod_name_en">
										安卓手机点此下载
									</span>
								</div>
							</div>
						</a>
						<a href="https://apps.apple.com/cn/app/%E5%B0%8F%E7%AB%B9-%E6%99%BA%E6%85%A7%E4%BD%93%E6%B8%A9%E7%AE%A1%E5%AE%B6/id1673635516" target="_blank">
							<div class="mp-product_download_app_link_ios">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/apple_icon.png" alt=""/>
								<div class="mp-product_download_app_link_ios_text">
									<span class="mp-product_download_app_link_ios_name">
										iPhone
									</span>
									<span class="mp-product_download_app_link_ios_name_en">
										苹果手机点此下载
									</span>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="mp-product_introduce_line"></div>

				<div class="mp-product_download_readme">
					<div class="mp-product_download_readme_title">电子说明书下载：</div>
					<div class="mp-product_download_readme_link">
						<a href="/file/ZT101.pdf" download="ZT101产品说明书.pdf">
							<div class="mp-product_download_zt101">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon-download.png" alt=""/>
								<div class="mp-product_download_readme_link_text">
									<span class="mp-product_download_readme_link_name">
										ZT101下载
									</span>
								</div>
							</div>
						</a>
						<a href="/file/ZT102.pdf" download="ZT102产品说明书.pdf">
							<div class="mp-product_download_zt102">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon-download.png" alt=""/>
								<div class="mp-product_download_readme_link_text">
									<span class="mp-product_download_readme_link_name">
										ZT102下载
									</span>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>


		<!--联系我们-->
		<div class="mp-concat">
			<div class="mp-concat-title-box" id="concat">
				<div class="mp-concat-title-left">
					<div class="mp-concat-title">联系我们</div>
					<div class="mp-concat-title-en">News & Events</div>
				</div>
				<div class="mp-concat-title-right">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more.png" alt="" />
				</div>
			</div>
			<div class="mp-concat-content">
				<div class="mp-concat-content-left">
					<div class="mp-concat-content-tel">
						<span>座机</span>
						<span>0451-84520036</span>
					</div>
					<div class="mp-concat-content-phone">
						<span>手机</span>
						<span>{{companyPhone}}</span>
					</div>
					<div class="mp-concat-content-email">
						<span>邮箱</span>
						<span>2022649023@qq.com</span>
					</div>
				</div>
				<div class="mp-concat-content-right">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_qrcode.png" alt="" />
					<span class="mp-concat-content-sacn">扫码关注了然</span>
				</div>
			</div>
			<div class="mp-concat-footer">
				<div class="mp-concat-footer-img">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/img-logo-white.png" alt="">
				</div>
				<div class="mp-concat-footer-company">
					<div class="mp-concat-footer-company-name">哈尔滨了然科技开发有限公司</div>
					<div class="mp-concat-footer-company-name-en">Harbin Liaoran Technology Development Co.</div>
				</div>
			</div>
		</div>


		<!-- 弹层内容 -->
		<van-popup v-model="showMenu" round position="top" :overlay-class="menuClass" :style="{ height: '65%' }">
			<div class="popup-content">
				<div class="menu-top">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_logo.png" alt="" />
				</div>
				<div class="menu-item" @click="jumpTo('/')">
					<van-icon name="wap-home-o" />
					<span>首页</span>
				</div>
				<div class="menu-item" @click="jumpTo('/mIndex?mount=product')">
					<van-icon name="apps-o" />
					<span>产品中心</span>
				</div>
				<div class="menu-item" @click="jumpTo('/mIndex?mount=about')">
					<van-icon name="records-o" />
					<span>关于企业</span>
				</div>
				<div class="menu-item" @click="toSection('download')">
					<van-icon name="down" />
					<span>下载中心</span>
				</div>
				<div class="menu-item" @click="jumpTo('/mIndex?mount=news')">
					<van-icon name="newspaper-o" />
					<span>新闻资讯</span>
				</div>
				<div class="menu-item" @click="toSection('concat')">
					<van-icon name="phone-o" />
					<span>联系我们</span>
				</div>
			</div>
		</van-popup>

		<!-- end -->
	</div>
</template>

<script>
import {data} from "../../assets/js/data.js";

export default {
	name: "productDetail",
	data() {
		return {
			showMenu: false,
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: data.companyPhone,
			companyEmail: "0451-84520036",
			menuClass: "custom-overlay"
		};
	},
	computed: {
		downAndroidloadUrl(){
			return "https://zhusonglan.com/app-release.apk?time=" + new Date().getTime();
		}
	},
	methods: {
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});

		},
		jumpTo(url){
			this.$router.push(url);
		}
	},
};
</script>
<style scoped>
@import "../../assets/css/mProductDet.css";
</style>
